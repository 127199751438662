@keyframes pulseEffect {
    0%, 20% { opacity: 0; }
    21% { opacity: 0; } /* Start transitioning */
    40%, 80% { opacity: 0.6; } /* Hold the transition */
    100% { opacity: 0; } /* Return to starting opacity smoothly */
}

@keyframes pulseEffectDefault {
    0%, 20% { opacity: 1; }
    21% { opacity: 1; } /* Start transitioning */
    40%, 80% { opacity: 0.5; } /* Hold the transition */
    100% { opacity: 1; } /* Return to starting opacity smoothly */
}


.photo-transition-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid rgb(0, 217, 255);
    /* border-radius: 21.2px; */
    /* border-radius: 15px; */
}

.photo-transition-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 2s ease; /* Smooth transition for hover effect */
    object-fit: cover;
}

.image-default {
    opacity: 1;
    animation: pulseEffectDefault 7s infinite;
}

.image-hover {
    opacity: 0; /* Correct initial state to hidden */
    animation: pulseEffect 7s infinite;
}

.photo-transition-container:hover .image-hover {
    opacity: 0.6; /* Desired hover state */
    animation: none; /* Stop animation on hover */
}

.photo-transition-container:hover .image-default {
    opacity: 0.5; /* Desired hover state */
    animation: none; /* Stop animation on hover */
}