.top-header {
    display: flex;
    justify-content: center; /* Center the icons container */
    align-items: center;
    background-color: #131A35;
    padding: 0 0 20px 0;
    width: 100%;
  }
  
  /* Container for the icons */
  .icons-container {
    display: flex;
    justify-content: space-between; /* Adjust spacing between the icons */
    align-items: center;
    /* width: 400px; */
    width: min(300px, 100%);
  }
  
  .icon-placeholder {
    /* width: 100%; */
    /* height: 100%; */
    /* background-color: #ffffff; */
    /* border-radius: 50%; */ 
    /* Removed margin as it's no longer needed here */
  }
  
  .icon-placeholder:hover{
    cursor:pointer;
  }